import type { ElementType } from 'react';
import classNames from 'classnames';
import type { PolymorphicComponentProp } from '@/types/react';

import styles from './index.module.scss';

interface CardBaseProps {
  readonly backgroundColors?: string | string[];
  readonly elevation?: 0 | 1 | 2 | 3 | 4;
  readonly cornerRadius?: 0 | 1 | 2 | 3 | 4;
}

export type CardProps<C extends ElementType = 'div'> = PolymorphicComponentProp<C, CardBaseProps>;

export function Card<C extends ElementType = 'div'>({
  children,
  as,
  backgroundColors,
  elevation = 0,
  cornerRadius = 0,
  onClick,
  className,
  ...props
}: CardProps<C>): JSX.Element {
  const AsComponent = as ?? 'div';

  const consolidatedClasses = classNames(
    styles.Card,
    cornerRadius && styles[`border-${cornerRadius}`],
    elevation && styles[`elevation-${elevation}`]
  );

  let instanceStyles: React.CSSProperties | undefined;

  if (backgroundColors) {
    if (Array.isArray(backgroundColors)) {
      instanceStyles = {
        background: `linear-gradient(to top, ${backgroundColors.join(',')})`,
      };
    } else {
      instanceStyles = {
        backgroundColor: backgroundColors,
      };
    }
  }

  return (
    <AsComponent
      style={instanceStyles}
      className={classNames(consolidatedClasses, className, Card.displayName)}
      onClick={onClick}
      {...props}
    >
      {children}
    </AsComponent>
  );
}
Card.displayName = 'Card';
