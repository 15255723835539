import type { HTMLAttributes } from 'react';
import React from 'react';
import { uniqueId } from 'lodash';
import { Img } from '@/components/atoms/Img';

const getOtherStars = (stars: IProps['stars']) => {
  const fullStars = Math.trunc(stars);
  // display half star if stars has decimals
  const halfStars = Math.ceil(stars - fullStars);
  const emptyStars = Math.max(5 - (fullStars + halfStars), 0);
  return [fullStars, halfStars, emptyStars];
};

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  readonly stars: number;
  readonly starHeight?: number;
  readonly starWidth?: number;
}

export default function ReviewStars({ stars = 4, starHeight, starWidth, ...props }: IProps) {
  const [fullStars, halfStars, emptyStars] = getOtherStars(stars);
  return (
    <div className="review-stars" {...props}>
      {Array(fullStars)
        .fill(null)
        .map(() => (
          <Img
            key={uniqueId('star')}
            provider="cloudinary"
            src="/icons/icon_star_aseapj.svg"
            alt="star"
            layout="fixed"
            width={starWidth ?? 18}
            height={starHeight ?? 18}
          />
        ))}
      {halfStars ? (
        <Img
          key={uniqueId('star')}
          provider="cloudinary"
          src="/icons/icon_half-star_xs7mhw.svg"
          alt="half star"
          layout="fixed"
          width={starWidth ?? 18}
          height={starHeight ?? 18}
        />
      ) : null}
      {Array(emptyStars)
        .fill(null)
        .map(() => (
          <Img
            key={uniqueId('star-empty')}
            provider="cloudinary"
            src="/icons/icon_empty-star_ipyoyo.svg"
            alt="empty star"
            layout="fixed"
            width={starWidth ?? 18}
            height={starHeight ?? 18}
          />
        ))}
    </div>
  );
}

ReviewStars.displayName = 'ReviewStars';
