import type { AnyZodObject, ZodDefault, ZodOptional, ZodNullable, ZodEffects, ZodPipeline } from 'zod';
import { z } from 'zod';

/**
 * Any zod object that can have defaults and be optional.
 */
export type AnyZodShape =
  | AnyZodObject
  | ZodDefault<AnyZodObject>
  | ZodOptional<AnyZodObject | ZodDefault<AnyZodObject>>
  | ZodNullable<AnyZodObject | ZodDefault<AnyZodObject>>
  | ZodEffects<AnyZodObject | ZodDefault<AnyZodObject>>
  | ZodPipeline<AnyZodShape, AnyZodShape>;

/**
 * Infer type from any validation object.
 */
export type SchemaType<T extends AnyZodShape> = Readonly<z.infer<T>>;

const LiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof LiteralSchema>;
type Json = Literal | { [key: string]: Json } | Json[];

/**
 * @link https://zod.dev/?id=json-type
 */
export const JsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([LiteralSchema, z.array(JsonSchema), z.record(JsonSchema)])
);
