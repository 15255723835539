'use client';

import type { ComponentPropsWithoutRef } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import vars from '$util/theme/vars';
import { Icon } from '@/components/atoms/Icon';
import type { QueuedModalDesign } from '@/types/components';

import styles from './index.module.scss';

export interface ModalProps extends ComponentPropsWithoutRef<'dialog'> {
  readonly closeCallback: () => void;
  readonly children: React.ReactNode;
  readonly behind?: boolean;
  readonly design?: QueuedModalDesign;
}

const matrixRegex = /matrix\(([0-9.]+),\s*[0-9.]+,\s*[0-9.]+,\s*[0-9.]+,\s*([0-9.]+),\s*([0-9.]+)\)/;

const MODAL_TRIGGER_FRAGMENT_PATTERN = /(#open-modal=.+|#)$/;

export function dispatchCloseQueuedModalEvent(target: Element): undefined {
  const closeModalEvent = new CustomEvent('closeModal', { bubbles: true });
  target.dispatchEvent(closeModalEvent);
}

export function Modal({
  className,
  closeCallback,
  behind,
  children,
  design = 'modal-drawer',
  ...props
}: ModalProps): JSX.Element | null {
  const asideElement = useRef(null);
  const modalContainer = useRef<HTMLDivElement>(null);

  const [shouldRender, setShouldRender] = useState(true);
  const [opened, setOpened] = useState(false);
  const [closeButtonSize, setCloseButtonSize] = useState(24);

  const closeModal = useCallback(() => {
    if (MODAL_TRIGGER_FRAGMENT_PATTERN.test(window.location.hash)) {
      window.history.replaceState(null, '', window.location.href.replace(MODAL_TRIGGER_FRAGMENT_PATTERN, ''));
    }
    setOpened(false);
  }, [setOpened]);

  useEffect(() => {
    if (modalContainer?.current) {
      const modalElement = modalContainer.current;

      setOpened(true);

      const handleAnimateAndClose = (event: TransitionEvent) => {
        const { propertyName, target } = event;

        if (propertyName === 'transform') {
          const { transform, height, width } = window.getComputedStyle(target as Element);

          const matches = transform.match(matrixRegex);

          // @NOTE keeping this WET and verbose for readability, because DRY & immutable style would be confusing

          if (design === 'modal-drawer') {
            // If we're in mobile...
            if (window.innerWidth < vars.mediaQueryBreakpoints.small) {
              // ...and we have a value for the ty transform
              if (matches?.[3]) {
                // ...and the value indicates it's been fully moved out of the viewport
                if (Math.ceil(parseFloat(matches[3])) >= parseInt(height, 10)) {
                  setShouldRender(false); // so nothing invisible is rendered on the DOM
                  closeCallback();
                }
              }
            }
            // If we're not in mobile...
            else if (window.innerWidth >= vars.mediaQueryBreakpoints.small) {
              // ...and we have a value for the tx transform
              if (matches?.[2]) {
                // ...and the value indicates it's been fully moved out of the viewport
                if (parseInt(matches[2], 10) >= parseInt(width, 10)) {
                  setShouldRender(false); // so nothing invisible is rendered on the DOM
                  closeCallback();
                }
              }
            }
          } else if (design === 'modal' || design === 'video-modal') {
            // for this one we only care about the scale for all viewports
            // ... and we have a value for the scale
            if (matches?.[1]) {
              // ...and the value indicates it's been shrunk down to nothing
              if (Math.ceil(parseFloat(matches[1])) <= 0) {
                setShouldRender(false); // so nothing invisible is rendered on the DOM
                closeCallback();
              }
            }
            if (design === 'video-modal') setCloseButtonSize(32);
          }
        }
      };

      modalElement.addEventListener('transitionend', handleAnimateAndClose);
      modalElement.addEventListener('closeModal', closeModal);

      return () => {
        modalElement.removeEventListener('transitionend', handleAnimateAndClose);
        modalElement.removeEventListener('closeModal', closeModal);
      };
    }
    return undefined;
  }, [modalContainer, closeCallback, closeModal, design]);

  return (
    (shouldRender && (
      <aside
        ref={asideElement}
        role="dialog"
        aria-modal="true"
        className={classNames(Modal.displayName, styles.component, design, className)}
        {...props}
      >
        <div
          ref={modalContainer}
          className={classNames(styles.modalContainer, opened && styles.open, behind && styles.behind)}
        >
          <button
            className={styles.closeButton}
            onClick={() => modalContainer.current && dispatchCloseQueuedModalEvent(modalContainer.current)}
            type="button"
            aria-label="Close"
          >
            <Icon id="general/x-close" width={closeButtonSize} height={closeButtonSize} legacy={false} />
          </button>
          <div className={styles.contentContainer}>
            {children}
            <div className={styles.mobileSpacing} />
          </div>
        </div>
      </aside>
    )) ||
    null
  );
}

Modal.displayName = 'Modal';
