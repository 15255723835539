'use client';

import type { ComponentPropsWithoutRef } from 'react';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Modal } from '$src-components/molecules/Modal';
import { useModalQueue } from '$util/hooks/useModalQueue';
import type { QueuedModalDesign } from '@/types/components';

import styles from './index.module.scss';

export interface QueuedModalsProps extends ComponentPropsWithoutRef<'div'> {
  readonly option?: string;
}

export function QueuedModals({ className, children, ...props }: QueuedModalsProps): JSX.Element {
  const [queue, , unqueueModal] = useModalQueue();

  // @NOTE lock the viewport scrolling when modals are open
  useEffect(() => {
    if (window.document && queue.length) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = '';
    }
  }, [queue]);

  return (
    <div
      className={classNames(
        QueuedModals.displayName,
        styles.component,
        queue.length && styles.open,
        className
      )}
      {...props}
    >
      {(queue?.length &&
        queue.map((ModalContent, i) => {
          const contents = Array.isArray(ModalContent) ? ModalContent[0] : ModalContent;
          const designClass: QueuedModalDesign = Array.isArray(ModalContent)
            ? ModalContent[1]
            : 'modal-drawer';

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Modal key={i} closeCallback={unqueueModal} behind={i < queue.length - 1} design={designClass}>
              {contents}
            </Modal>
          );
        })) ||
        null}
    </div>
  );
}
QueuedModals.displayName = 'QueuedModals';
