import config from 'config';
import { CLOUDINARY_URL } from '$constants/cloudinary';

export const getMapImage = (
  [longitude, latitude]: [number | undefined, number | undefined],
  [width, height]: [number, number],
  options: { [key: string]: any }
) => {
  const opts = {
    marker: `${CLOUDINARY_URL}/map_marker_axurpo.png`,
    zoom: 16,
    ...options,
  };
  return `https://maps.googleapis.com/maps/api/staticmap?key=${config.get(
    'public.gMaps.apiKey'
  )}&center=${latitude},${longitude}&zoom=${
    opts.zoom
    // eslint-disable-next-line max-len
  }&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x5ce7ff%7Cvisibility:off&style=element:labels.text.stroke%7Ccolor:0xb3d3f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:landscape.man_made%7Ccolor:0xf5f8fc&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe1f3ff&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=${width}x${height}&markers=icon:${
    opts.marker
  }|${latitude},${longitude}`;
};

export const DEFAULT_LAT_LNG = Object.freeze({
  FR: {
    name: 'FR',
    centerLatLng: {
      lat: 48.864716,
      lng: 2.349014,
    },
    zoom: 12,
  },
  UK: {
    name: 'UK',
    centerLatLng: {
      lat: 52.450355,
      lng: -1.592563,
    },
    zoom: 12,
  },
});
